import React from 'react'
import CookieConsent from 'react-cookie-consent'

const CookieConsentBanner = () => {
  return (
    <CookieConsent
      containerClasses="cookie-banner"
      buttonClasses="cookie-banner__btn"
      sameSite="strict"
    >
      By using this website, you agree to the <a href="/legal/privacy-policy">Privacy Policy</a> and
      the use of cookies.
    </CookieConsent>
  )
}

export default CookieConsentBanner
