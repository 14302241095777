import React from 'react'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl'
import upperCase from 'lodash/upperCase'
import styled from '@emotion/styled'
import { DESKTOP_MIN_BREAKPOINT } from '../constants/breakpoints'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const LangButton = styled.button`
  background-color: inherit;
  cursor: pointer;
  font-weight: ${({ currentLocale, language }) => (currentLocale === language ? `bold` : `normal`)};
  height: 3rem;
  border: none;
  @media screen and (${DESKTOP_MIN_BREAKPOINT}) {
    margin: 0 0.5rem;
  }
`

const VerticalSeparator = styled.span`
  border-left: 1px solid;
  height: 2rem;
  @media screen and (${DESKTOP_MIN_BREAKPOINT}) {
    height: 3rem;
  }
`

const LanguageSwitcher = function() {
  return (
    <Wrapper>
      <IntlContextConsumer>
        {({ languages: [en, de], language: currentLocale }) => (
          <>
            <LangButton
              className="color-greyblack is-size-7 is-size-4-desktop"
              currentLocale={currentLocale}
              language={en}
              onClick={() => changeLocale(en)}
            >
              {upperCase(en)}
            </LangButton>
            <VerticalSeparator className="color-greyblack" />
            <LangButton
              className="color-greyblack is-size-7 is-size-4-desktop"
              currentLocale={currentLocale}
              language={de}
              onClick={() => changeLocale(de)}
            >
              {upperCase(de)}
            </LangButton>
          </>
        )}
      </IntlContextConsumer>
    </Wrapper>
  )
}

export default LanguageSwitcher
