import React from 'react'
import styled from '@emotion/styled'
import upperCase from 'lodash/upperCase'
import { Grid, Col, Row } from 'react-flexbox-grid'
import { injectIntl, Link } from 'gatsby-plugin-intl'

import logo from '../img/lkp-logo.svg'
import navLinks from '../navLinks'
import { TABLET_MIN_BREAKPOINT, DESKTOP_MIN_BREAKPOINT } from '../constants/breakpoints'
import LanguageSwitcher from './LanguageSwitcher'

const NAVBAR_MAX_HEIGHT_MOBILE = '6rem'
const NAVBAR_MAX_HEIGHT_DESKTOP = '10rem'

const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${NAVBAR_MAX_HEIGHT_MOBILE};

  @media screen and (${DESKTOP_MIN_BREAKPOINT}) {
    height: ${NAVBAR_MAX_HEIGHT_DESKTOP};
  }
`

const MenuButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const BurgerMenuButton = styled.div`
  display: flex;
  align-items: center;

  @media screen and (${DESKTOP_MIN_BREAKPOINT}) {
    width: 8rem;
    > span {
      width: 32px;
      height: 2px;
      :nth-child(1) {
        top: calc(50% - 10px);
      }
      :nth-child(3) {
        top: calc(50% + 8px);
      }
    }
  }
`

const LogoImg = styled.img`
  height: ${NAVBAR_MAX_HEIGHT_MOBILE};

  @media screen and (${DESKTOP_MIN_BREAKPOINT}) {
    height: ${NAVBAR_MAX_HEIGHT_DESKTOP};
  }
`

const CloseMenuButton = styled.div`
  display: block;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;

  > span {
    left: auto;
    width: 2rem;
    height: 2px;
  }

  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    top: 2rem;
    right: 2rem;
  }

  @media screen and (${DESKTOP_MIN_BREAKPOINT}) {
    top: 2rem;
    right: 4rem;
    width: 3rem;
    height: 3rem;
    > span {
      left: auto;
      width: 3rem;
      height: 3px;
    }
  }
`

const OverlayContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState({
      active: !this.state.active,
    })
  }

  isActiveRoute = path =>
    typeof window !== 'undefined' ? path === window.location.pathname : false

  render() {
    const { intl } = this.props
    return (
      <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation">
        <NavbarContainer>
          <Grid fluid style={{ width: '100%' }}>
            <Row>
              <Col xs={4}>
                <LanguageSwitcher />
              </Col>
              <Col xs={4}>
                <div className="has-text-centered">
                  <Link to="/">
                    <LogoImg src={logo} alt="Linda Kremer Photography Logo" />
                  </Link>
                </div>
              </Col>
              <Col xs={4}>
                <MenuButtonWrapper>
                  <BurgerMenuButton
                    className={`navbar-burger burger`}
                    data-target="navMenu"
                    onClick={() => this.toggleHamburger()}
                  >
                    <span />
                    <span />
                    <span />
                  </BurgerMenuButton>
                </MenuButtonWrapper>
              </Col>
            </Row>
          </Grid>
          <div className={`overlay ${this.state.active ? 'overlay-active' : ''}`}>
            <OverlayContent>
              <CloseMenuButton
                className={`navbar-burger burger is-active`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </CloseMenuButton>
              {navLinks.map(({ key, path }) => (
                <Link
                  key={key}
                  className={`navbar-item has-text-centered is-size-3-mobile is-size-2-tablet is-size-1-widescreen ${
                    this.isActiveRoute(`/${intl.locale}${path}`) ? 'is-active-link' : ''
                  }`}
                  to={path}
                >
                  {upperCase(intl.formatMessage({ id: key }))}
                </Link>
              ))}
            </OverlayContent>
          </div>
        </NavbarContainer>
      </nav>
    )
  }
}

export default injectIntl(Navbar)
