export default [
  { key: 'nav.home', path: '/' },
  { key: 'nav.in-situ', path: '/in-situ' },
  { key: 'nav.portfolio', path: '/portfolio' },
  { key: 'nav.wall-art', path: '/wall-art' },
  { key: 'nav.commissions', path: '/commissions' },
  { key: 'nav.about', path: '/about' },
  { key: 'nav.contact', path: '/contact' },
  // { key: 'Giving Back', path: '/giving-back' },
  // { key: 'Blog', path: '/blog' },
]
