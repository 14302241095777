import React from 'react'
import { Grid, Col, Row } from 'react-flexbox-grid'
import { useIntl } from 'gatsby-plugin-intl'

import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'

const Footer = () => {
  const { formatMessage } = useIntl()
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <div className="container">
          <Grid fluid>
            <Row>
              <Col xs={12}>
                <div className="social">
                  <a
                    title="facebook"
                    href="https://www.facebook.com/LindaKremerPhotography"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebook} alt="Facebook" style={{ width: '1em', height: '1em' }} />
                  </a>
                  <a
                    title="instagram"
                    href="https://www.instagram.com/lindakremer_photography/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={instagram} alt="Instagram" style={{ width: '1em', height: '1em' }} />
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div style={{ padding: '0 0 2rem 0' }}>
                  <p>
                    <a alt="Privacy Policy" href="/legal/privacy-policy">
                      {formatMessage({ id: 'footer.privacy-policy' })}
                    </a>
                  </p>
                  <p>
                    <a alt="Legal Disclosure" href="/legal/legal-disclosure">
                      {formatMessage({ id: 'footer.legal-disclosure' })}
                    </a>
                  </p>
                  <p>
                    {formatMessage(
                      { id: 'footer.copyright | fullYear' },
                      { fullYear: new Date().getFullYear() }
                    )}
                  </p>
                  <p>
                    {formatMessage({ id: 'footer.developed-by' })}
                    <a
                      rel="noopener"
                      alt="Ben Kremer - Software Developer Portfolio"
                      href="https://bkrem.dev"
                    >
                      Ben Kremer
                    </a>
                    .
                  </p>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    </footer>
  )
}

export default Footer
