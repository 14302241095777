import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import styled from '@emotion/styled'

import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import useSiteMetadata from './SiteMetadata'
import CookieConsentBanner from './CookieConsentBanner'
import { TABLET_MIN_BREAKPOINT } from '../constants/breakpoints'
import './all.sass'
import { useIntl } from 'gatsby-plugin-intl'

const Section = styled.section`
  min-height: 75vh;
  overflow-x: hidden;
  padding: 1.5rem 0;
  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    padding: 3rem 1.5rem;
  }
`

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  const { locale } = useIntl()
  return (
    <div>
      <Helmet>
        <html lang={locale} />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#000000"
        />

        <link rel="manifest" href={`${withPrefix('/')}site.webmanifest`} />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix('/')}img/android-chrome-512x512.png`} />

        {/* Global site tag (gtag.js) - Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-174665240-1"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-174665240-1');`}
        </script>
      </Helmet>
      <CookieConsentBanner />
      <Navbar />
      <Section>
        <div className="container">
          <div className="columns">{children}</div>
        </div>
      </Section>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
